import { createContext } from "react";

export default createContext({
  countryCode: "GB",
  language: "en-GB",
  storeCode: "COM",
  keyStoreDataversion: "",
  myAccountUrl: "https://my.asos.com",
  nuanceDataCenter: "eu2",
  nuanceTagServerName: "asos-preprod.lle",
  nuanceVendorUUID: "10007081",
  currentUrl: "",
});
