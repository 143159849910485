import { configureRequest } from "@asosteam/asos-web-request";
import noticeError from "src/app/utilities/noticeError";
import logger from "src/app/utilities/logger";

export default async function getOrders({ authHeader, limit = 3, offset = 0 }) {
  try {
    const domain = window.location.origin;
    const url = `${domain}/customer-care/orders/`;
    const request = configureRequest({
      defaultTimeout: 10000,
      retries: 2,
      retryDelay: () => 500,
      logger,
      client: {
        name: "asos-contentweb-customer-care-help-pages",
        version: "1.0.0",
        platform: "CPT",
      },
    });
    const params = {
      limit,
      offset,
    };

    const response = await request.get({
      url,
      headers: {
        Authorization: authHeader,
        Accept: "application/json",
      },
      params,
    });
    return response.data;
  } catch (error) {
    noticeError(error);

    throw error;
  }
}
