const loadBundle = (fileName) =>
  import(
    /* webpackChunkName: "translations" */ `../languages/${fileName}.json`
  );

const getBundle = async (fileName) => {
  try {
    return await loadBundle(fileName);
  } catch (err) {
    // Don't need to report to new relic as a missing bundle here is usually not a problem
    return {};
  }
};

const buildTranslationBundle = async (languageCode, getBundleFn = getBundle) =>
  await getBundleFn(languageCode);

const getTranslations = (languageCode, getBundleFn = getBundle) =>
  buildTranslationBundle(languageCode, getBundleFn);

export default getTranslations;
