export const toLowerCase = (string = "") => string.toLowerCase();

export default (eventName, props = {}) => {
  if (typeof window === "undefined") return;

  const commonProps = {
    channel: "help",
    prop31: props.loginStatus,
    eVar10: props.customerId,
  };
  const server = props.server || (window.s && window.s.server);

  if (!props.status) {
    // Fill these values in if the error is unknown
    props.status = "500";
    props.breadcrumb = "customer-care";
    props.errorMessage = "Request failed with status code 500";
  }

  const getUriFragmentValueByName = (name) => {
    return new URLSearchParams(window.location.hash.slice(1)).get(name);
  };

  const getEVar180ForHelpPages = () => {
    const appPlatform = getUriFragmentValueByName("app_platform");

    if (appPlatform === "ios") {
      return "iOS app";
    }

    if (appPlatform === "android") {
      return "android app";
    }

    const device = window.asos.webContext.getPlatform();
    return `${device} web`;
  };

  const getFaqAnswerPageConfig = () => {
    const { articleName, itemId, category } = props;

    const paidReturnFeeMessage = getUriFragmentValueByName("feemsg");

    const config = {
      linkVars: {
        pageName: `${server}|help page|faq|${articleName}`,
        prop1: "help|faq",
        prop8: "help page",
        prop30: toLowerCase(articleName),
        eVar180: getEVar180ForHelpPages(),
        eVar20: `help|${category}`,
        eVar43: itemId,
        trackDownloadLinks: false,
        ...commonProps,
      },
      eventVars: {},
    };

    if (paidReturnFeeMessage) {
      config.linkVars.prop6 = paidReturnFeeMessage;
      config.eventVars = { ...config.eventVars, event323: "event323" };
    }

    return config;
  };

  const config = {
    "faq-answer-page": getFaqAnswerPageConfig(),
    "homepage": {
      linkVars: {
        pageName: `${server}|help page|help home`,
        prop1: "help home",
        prop8: "help page",
        eVar180: getEVar180ForHelpPages(),
        ...commonProps,
      },
      eventVars: {},
    },
    "faq-listing-page": {
      linkVars: {
        pageName: `${server}|help page|${props.category}`,
        prop1: props.category,
        prop8: "help page",
        eVar180: getEVar180ForHelpPages(),
        eVar20: `help|${props.category}`,
        ...commonProps,
      },
      eventVars: {},
    },
    "search-results-page": {
      linkVars: {
        pageName: `${server}|help page|search results`,
        prop1: "search results",
        prop8: "help page",
        prop14: "freeform",
        prop44: `${props.itemCount}`,
        eVar180: getEVar180ForHelpPages(),
        eVar143: `${props.query}`,
        ...commonProps,
      },
      eventVars: {
        event200: "event200",
      },
    },
    "get-in-touch-page": {
      linkVars: {
        pageName: `${server}|help page|get in touch`,
        prop1: "get in touch",
        prop6: "sidebar|get in touch|help home",
        prop8: "help page",
        eVar180: getEVar180ForHelpPages(),
        eVar20: "help| get in touch",
        eVar46: "help home|get in touch",
        ...commonProps,
      },
      eventVars: {},
    },
    "error-page": {
      linkVars: {
        pageName: `${server}|help page|error page`,
        prop1: `${props.breadcrumb}`,
        prop6: `help home|${props.breadcrumb}|home`,
        prop8: "help page",
        prop52: `API error|${props.status}|${props.errorMessage}`,
        eVar180: getEVar180ForHelpPages(),
        eVar20: "help",
        ...commonProps,
      },
      eventVars: {
        event173: "event173",
      },
    },
    "related-questions-load-more": {
      linkVars: {
        pageName:
          props.page === "homepage"
            ? `${server}|help page|help home|page ${props.pageNumber}`
            : `${server}|help page|faq|${props.articleName}|page ${props.pageNumber}`,
      },
      eventVars: {
        event9: "event9",
      },
    },
    "faq-list-load-new-page": {
      linkVars: {
        pageName: `${server}|help page|${props.category} page ${props.pageNumber}`,
      },
      eventVars: {
        event9: "event9",
      },
    },
    "search-results-load-next": {
      linkVars: {
        pageName: `${server}|help page|search results ${props.pageNumber}`,
      },
      eventVars: {
        event9: "event9",
      },
    },
    "search-results-load-previous": {
      linkVars: {
        pageName: `${server}|help page|search results ${props.pageNumber}`,
      },
      eventVars: {
        event9: "event9",
      },
    },
    "attachments-download": {
      eventName: "",
      linkType: "d",
      linkVars: {
        eVar46: `${props.category} | download`,
        eVar160: props.itemName,
      },
      eventVars: {
        event76: "event76",
      },
    },
    "get-in-touch-channels": {
      linkVars: {
        pageName: `${server}|help page|get in touch`,
        prop1: "get in touch",
        prop8: "help page",
        eVar180: getEVar180ForHelpPages(),
        eVar20: "help|get in touch",
        eVar46: `get in touch|${props.channelName}`,
        ...commonProps,
      },
      eventVars: {
        event76: "event76",
      },
    },
    "service-messages": {
      linkVars: {
        pageName: `${server}|help page|help home`,
        prop1: "help home",
        prop6: "help home|home",
        prop8: "help page",
        eVar180: getEVar180ForHelpPages(),
        ...commonProps,
      },
      eventVars: {
        event244: "event244",
      },
    },
    "external-link": {
      linkVars: {
        pageName: `${server}|help page|faq|${props.articleName}`,
        prop30: toLowerCase(props.articleName),
        prop6: `&ctaref= help article|${props.text}|${props.category}`,
        eVar10: props.customerId,
        ...commonProps,
      },
    },
    "send-an-email-page": {
      linkVars: {
        pageName: `${server}|help page|suae step 1`,
        prop1: "suae step 1",
        prop8: "help page",
        eVar180: getEVar180ForHelpPages(),
        eVar20: "help|get in touch",
        ...commonProps,
      },
    },
    "send-an-email-page-step-4": {
      linkVars: {
        pageName: `${server}|help page|suae step 4`,
        prop1: "suae step 4",
        prop8: "help page",
        eVar180: getEVar180ForHelpPages(),
        eVar20: "help|get in touch",
        ...commonProps,
      },
    },
    "send-an-email-page-send": {
      linkVars: {
        pageName: `${server}|help page|suae step 4`,
        prop1: "suae step 4",
        prop6: "help suae|get in touch",
        prop8: "help page",
        eVar180: getEVar180ForHelpPages(),
        eVar20: "help|get in touch",
        eVar46: "suae step 4|send",
        ...commonProps,
      },
      eventVars: {
        event76: "event76",
      },
    },
    "send-an-email-page-error": {
      linkVars: {
        pageName: `${server}|help page|suae step 4`,
        prop1: "suae step 4",
        prop6: "help suae|get in touch",
        prop8: "help page",
        eVar180: getEVar180ForHelpPages(),
        eVar20: "help|get in touch",
        eVar46: "suae step 4|error",
        ...commonProps,
      },
      eventVars: {
        event173: "event173",
      },
    },
    "live-chat-page": {
      linkVars: {
        pageName: `${server}|help page|aaq step 1`,
        prop1: "aaq step 1",
        prop8: "help page",
        eVar180: getEVar180ForHelpPages(),
        eVar20: "help|get in touch",
        ...commonProps,
      },
    },
    "live-chat-page-step-4": {
      linkVars: {
        pageName: `${server}|help page|aaq step 4`,
        prop1: "aaq step 4",
        prop8: "help page",
        eVar180: getEVar180ForHelpPages(),
        eVar20: "help|get in touch",
        ...commonProps,
      },
    },
    "live-chat-page-send": {
      linkVars: {
        pageName: `${server}|help page|aaq step 4`,
        prop1: "aaq step 4",
        prop6: "help aaq|get in touch",
        prop8: "help page",
        eVar180: getEVar180ForHelpPages(),
        eVar20: "help|get in touch",
        eVar46: "aaq step 4|send",
        ...commonProps,
      },
      eventVars: {
        event76: "event76",
      },
    },
    "live-chat-page-error": {
      linkVars: {
        pageName: `${server}|help page|aaq step 4`,
        prop1: "aaq step 4",
        prop6: "help aaq|get in touch",
        prop8: "help page",
        eVar180: getEVar180ForHelpPages(),
        eVar20: "help|get in touch",
        eVar46: "aaq step 4|error",
        ...commonProps,
      },
      eventVars: {
        event173: "event173",
      },
    },
    "my-account-button": {
      linkVars: {
        eVar46: `${props.page}|my account`,
        ...commonProps,
      },
      eventVars: {
        event76: "event76",
      },
    },
    "track-order-button": {
      linkVars: {
        eVar106: "chat now|select order|track order|click",
        eVar103: "chat now|track order|select order",
      },
      eventVars: {
        event293: "event293",
      },
    },
  };

  return config[eventName];
};
