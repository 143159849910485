import React from "react";
import PropTypes from "prop-types";
import DisappointedIcon from "src/assets/icons/disappointed.svg";
import styles from "./ErrorBoundary.module.css";
import noticeError from "src/app/utilities/noticeError";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      error: "",
      errorInfo: "",
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error, errorInfo) {
    noticeError({ error, errorInfo });
    this.setState({ errorInfo });
  }

  render() {
    const { hasError, error, errorInfo } = this.state;
    const isDevelopment = process.env.NODE_ENV === "development";

    if (hasError) {
      return (
        <div className={styles.error}>
          <DisappointedIcon />
          <h1>Oops</h1>
          <p>Something went wrong. Please try again.</p>
          {isDevelopment && errorInfo && (
            <details>
              <summary>Click for error details</summary>
              <p>{error.toString()}</p>
              <p>{errorInfo.componentStack.toString()}</p>
            </details>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
