import { combineReducers } from "redux";
import storeData from "src/app/state/reducers/store-data";
import contactForm from "src/app/state/reducers/contact-form";
import content from "src/app/state/reducers/content";
import previewing from "src/app/state/reducers/previewing";
import getInTouch from "src/app/state/reducers/get-in-touch";

const reducers = {
  storeData,
  contactForm,
  content,
  previewing,
  getInTouch,
  floor: (state = {}) => state,
};

const appReducer = combineReducers({
  ...reducers,
});

export default appReducer;
