const sdkExists = (sdkName) => window.asos.sdk && window.asos.sdk[sdkName];

const sdkProvider = (sdkName) =>
  new Promise((resolve) => {
    const resolveSdk = () => {
      const sdk = window.asos.sdk[sdkName];
      resolve(sdk);
    };

    if (sdkExists(sdkName)) {
      return resolveSdk();
    }

    window.asos.eventBus.once(`${sdkName}-sdk-ready`, resolveSdk);
  });

export default sdkProvider;
