import { trackPageLoad } from "asos-contentweb-analytics-middleware";
import sdkProvider from "./sdkProvider";
import analyticsProvider from "../analyticsProvider";
import getUserLoginStatus from "./getUserLoginStatus";
import noticeError from "src/app/utilities/noticeError";

export default async ({ content = {}, searchResults = {} }) => {
  try {
    const {
      layout,
      analytics: { articleName = "", itemId = "", category = "" } = {},
    } = content;
    const identity = await sdkProvider("identity");
    const isRecognised = (await identity.customer.isRecognised?.()) || false;
    const isAuthenticated = await identity.customer.isAuthenticated();
    const customerId = await identity.customer.customerId();
    const { platform = "", storeId = "" } =
      window.asos.webContext.getWebContext();
    const server = `${platform} ${storeId.toLowerCase()}`;

    let pageLoadProperties = {
      server,
      customerId,
      loginStatus: getUserLoginStatus({ isRecognised, isAuthenticated }),
      articleName: articleName.toLowerCase(),
      itemId,
      category: category.toLowerCase(),
    };

    if (layout === "search-results-page") {
      const { query, itemCount } = searchResults;
      pageLoadProperties = {
        ...pageLoadProperties,
        itemCount,
        query: query.toLowerCase(),
      };
    }

    if (layout === "error-page") {
      const {
        errorMessage,
        status,
        data: { breadcrumb },
      } = content;
      pageLoadProperties = {
        ...pageLoadProperties,
        errorMessage,
        status,
        breadcrumb: breadcrumb && breadcrumb[breadcrumb.length - 1].Label,
      };
    }

    const config = analyticsProvider(layout, pageLoadProperties);
    await trackPageLoad(config);
  } catch (e) {
    noticeError(e);
  }
};
