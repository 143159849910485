import { configureRequest } from "@asosteam/asos-web-request";
import noticeError from "src/app/utilities/noticeError";
import logger from "src/app/utilities/logger";

export default async function getLatestTrackAndTraceEvent({
  authHeader,
  orderReference,
}) {
  const domain = window.location.origin;
  const url = `${domain}/customer-care/track-parcel/${orderReference}`;
  const request = configureRequest({
    retries: 2,
    defaultTimeout: 10000,
    retryDelay: () => 100,
    logger,
    client: {
      name: "asos-contentweb-customer-care-help-pages",
      version: "1.0.0",
      platform: "CPT",
    },
  });

  try {
    const response = await request.get({
      url,
      headers: {
        Authorization: authHeader,
      },
    });

    return response.data;
  } catch (error) {
    noticeError(error);

    return false;
  }
}
