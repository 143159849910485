/* eslint-disable react/display-name */
import React from "react";

const tags = {
  strong: {
    open: "<strong>",
    close: "</strong>",
    jsx: (text, index) =>
      React.createElement(
        "strong",
        { key: index, style: { fontWeight: 700 } },
        text
      ),
  },
  a: {
    open: "<a ([^>]+)>",
    close: "</a>",
    mapAttributesToProps: ({ href }) => ({
      href: href,
    }),
    jsx: (text, index, props) => {
      return React.createElement("a", { ...props, key: index }, text);
    },
  },
};

export default tags;
