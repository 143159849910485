import "./publicPath";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import StoreDataContext from "src/app/context/store-data";
import layoutHandler from "./layouts/Layout";
import styles from "./index.module.css";
import GlobalCSS from "./styles/global.module.css"; // eslint-disable-line
import trackPageLoad from "../utilities/trackPageLoad";
import ErrorBoundary from "src/app/components/ErrorBoundary/ErrorBoundary";
import noticeError from "src/app/utilities/noticeError";

export default function App({ store }) {
  const Layout = layoutHandler(store.content.layout);

  useEffect(() => {
    try {
      trackPageLoad(store);
    } catch (err) {
      noticeError(err);
    }
  }, []);

  return (
    <StoreDataContext.Provider value={store.storeData}>
      <div className={styles.appLayout}>
        <ErrorBoundary>
          <Layout
            content={store.content}
            storeData={store.storeData}
            searchResults={
              store.content && store.content.layout === "search-results-page"
                ? store.searchResults
                : null
            }
            faqItems={
              store.content && store.content.layout === "faq-listing-page"
                ? store.faqItems
                : null
            }
          />
        </ErrorBoundary>
      </div>
    </StoreDataContext.Provider>
  );
}

App.propTypes = {
  store: PropTypes.object,
  searchResults: PropTypes.object,
};
