/* eslint-disable no-underscore-dangle */
/* eslint-disable react/display-name */
import React from "react";
import { hydrateRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { loadableReady } from "@loadable/component";
import { LocaleProvider } from "@asosteam/asos-web-react-i18n";
import { configureStore } from "src/app/state";
import App from "../app";
import getTranslations from "../i18n";
import tags from "../i18n/tags";

const initialState = window.__INITIAL_STATE__;
const { onAKS, version } = initialState.storeData;

window?.newrelic?.setCustomAttribute("version", version);
window?.newrelic?.setCustomAttribute("onAKS", onAKS);
window?.newrelic?.addRelease("cchp", version);

//delete window.__INITIAL_STATE__

const store = configureStore(initialState);

const start = async () => {
  const language = initialState.storeData.language;
  const siteRoot = initialState.storeData.currentUrl;
  const translations = await getTranslations(language);

  loadableReady(() => {
    hydrateRoot(
      document.getElementById("react-root"),
      <Provider store={store}>
        <LocaleProvider
          language={language}
          siteRoot={siteRoot}
          translations={translations}
          tags={tags}
        >
          <App store={initialState} />
        </LocaleProvider>
      </Provider>
    );
  });
};

start();
