const initialState = {
  countryCode: "GB",
  language: "en-GB",
  storeCode: "COM",
  countries: [
    {
      value: "GB",
      label: "UK",
    },
  ],
};

export default function storeData(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
