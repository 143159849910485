/* eslint-disable complexity */
import {
  SET_TOPIC,
  SET_SUBTOPIC,
  SKIP_LOGIN,
  SET_STEP_ONE,
  SET_STEP_TWO,
  SET_ORDER,
  SKIP_ORDER,
  RESET_ORDER,
  SET_ITEM_NAME,
  SET_TRACK_AND_TRACE,
  REQUEST_ORDERS,
  RECEIVE_ORDERS,
  REQUEST_MORE_ORDERS,
  RECEIVE_MORE_ORDERS,
} from "src/app/state/actions/contact-form";

const initialState = {
  isAuthenticated: false,
  skipLogin: false,
  topic: false,
  subtopic: false,
  layout: "",
  step: 0,
  stepOne: false,
  stepTwo: false,
  itemName: false,

  order: false,
  orders: [],
  loadingOrders: true,
  totalOrders: 0,
  visibleOrders: 0,
  showLoadMoreOrdersButton: false,
  loadingMoreOrders: false,
  skipOrderSelector: false,

  loadingTrackAndTrace: false,
  hasTrackAndTrace: false,
  parcelStatusId: 0,
  statusAchievedDateTime: "",
};

export default function contactForm(state = initialState, action) {
  switch (action.type) {
    case SET_TOPIC:
      return Object.assign({}, state, {
        topic: action.topic,
        subtopic: false,
        isAuthenticated: action.isAuthenticated,
        order: false,
        itemName: false,
        stepOne: false,
        stepTwo: false,
      });

    case SET_SUBTOPIC:
      return Object.assign({}, state, {
        subtopic: action.subtopic,
        isAuthenticated: action.isAuthenticated,
        step: action.step,
        order: false,
        itemName: false,
        stepOne: false,
        stepTwo: false,
      });

    case SKIP_LOGIN:
      return Object.assign({}, state, {
        skipLogin: true,
      });

    case SET_STEP_ONE:
      return Object.assign({}, state, {
        step: action.step,
        stepOne: {
          name: action.name,
          value: action.value,
        },
        order: false,
        itemName: false,
        stepTwo: false,
      });

    case SET_STEP_TWO:
      return Object.assign({}, state, {
        step: action.step,
        stepTwo: {
          name: action.name,
          value: action.value,
        },
        order: false,
        itemName: false,
      });

    case REQUEST_ORDERS:
      return Object.assign({}, state, {
        loadingOrders: true,
      });

    case RECEIVE_ORDERS:
      return Object.assign({}, state, {
        orders: [...state.orders, ...action.orders],
        loadingOrders: false,
        totalOrders: action.totalOrders,
        visibleOrders: action.visibleOrders,
        showLoadMoreOrdersButton: action.showLoadMoreOrdersButton,
      });

    case SET_ORDER:
      return Object.assign({}, state, {
        step: action.step,
        order: action.order,
        itemName: false,
        loadingTrackAndTrace: action.loadingTrackAndTrace,
      });

    case SKIP_ORDER:
      return Object.assign({}, state, {
        step: 5,
        skipOrderSelector: true,
      });

    case RESET_ORDER:
      return Object.assign({}, state, {
        step: 3,
        order: false,
        skipOrderSelector: false,
      });

    case REQUEST_MORE_ORDERS:
      return Object.assign({}, state, {
        loadingMoreOrders: true,
      });

    case RECEIVE_MORE_ORDERS:
      return Object.assign({}, state, {
        orders: [...state.orders, ...action.orders],
        loadingMoreOrders: false,
        totalOrders: action.totalOrders,
        visibleOrders: action.visibleOrders,
        showLoadMoreOrdersButton: action.showLoadMoreOrdersButton,
      });

    case SET_ITEM_NAME:
      return Object.assign({}, state, {
        itemName: action.itemName,
        step: action.step,
      });

    case SET_TRACK_AND_TRACE:
      return Object.assign({}, state, {
        parcelStatusId: action.parcelStatusId,
        statusAchievedDateTime: action.statusAchievedDateTime,
        hasTrackAndTrace: action.hasTrackAndTrace,
        loadingTrackAndTrace: false,
        step: 5,
      });

    default:
      return state;
  }
}
