import thunkMiddleware from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import appReducer from "src/app/state/reducers";

const composeEnhancer = composeWithDevTools({ name: "Customer Care" });

export const configureStore = (initialState) => {
  const store = createStore(
    appReducer,
    initialState,
    composeEnhancer(applyMiddleware(thunkMiddleware))
  );

  return store;
};
