import loadable from "@loadable/component";

const getLayoutComponent = (layoutProperty) => {
  const layout = layoutMapObject[layoutProperty];
  if (typeof layout !== "undefined") {
    return layout;
  }
  return loadable(() => import("./ErrorPage/"));
};

const layoutMapObject = {
  "faq-answer-page": loadable(() => import("./FaqAnswer/")),
  "faq-listing-page": loadable(() => import("./FaqListing/")),
  "search-results-page": loadable(() => import("./SearchResults/")),
  "homepage": loadable(() => import("./Homepage/")),
  "get-in-touch-page": loadable(() => import("./GetInTouch/")),
  "error-page": loadable(() => import("./ErrorPage/")),
  "send-an-email-page": loadable(() => import("./SendAnEmail/SendAnEmail")),
  "live-chat-page": loadable(() => import("./LiveChat/LiveChat")),
};

export default getLayoutComponent;
