import getLatestTrackAndTraceEvent from "src/app/utilities/getLatestTrackAndTraceEvent";
import sdkProvider from "src/utilities/sdkProvider";
import getOrders from "src/app/utilities/getOrders";

export const SET_TOPIC = "SET_TOPIC";
export const SET_SUBTOPIC = "SET_SUBTOPIC";
export const SKIP_LOGIN = "SKIP_LOGIN";
export const SET_STEP_ONE = "SET_STEP_ONE";
export const SET_STEP_TWO = "SET_STEP_TWO";
export const SET_ORDER = "SET_ORDER";
export const SKIP_ORDER = "SKIP_ORDER";
export const RESET_ORDER = "RESET_ORDER";
export const REQUEST_ORDERS = "REQUEST_ORDERS";
export const RECEIVE_ORDERS = "RECEIVE_ORDERS";
export const REQUEST_MORE_ORDERS = "REQUEST_MORE_ORDERS";
export const RECEIVE_MORE_ORDERS = "RECEIVE_MORE_ORDERS";
export const SET_ITEM_NAME = "SET_ITEM_NAME";
export const SET_TRACK_AND_TRACE = "SET_TRACK_AND_TRACE";

export const setTopic = ({ topic }) => {
  return { type: SET_TOPIC, topic };
};

export const setSubtopic = ({ subtopic, isAuthenticated, step }) => {
  return { type: SET_SUBTOPIC, subtopic, isAuthenticated, step };
};

export const setSkipLogin = () => {
  return { type: SKIP_LOGIN };
};

export const setStepOne = ({ name, value, step }) => {
  return { type: SET_STEP_ONE, name, value, step };
};

export const setStepTwo = ({ name, value, step }) => {
  return { type: SET_STEP_TWO, name, value, step };
};

export const setItemName = ({ itemName, step }) => {
  return { type: SET_ITEM_NAME, itemName, step };
};

export const requestOrders = () => {
  return { type: REQUEST_ORDERS };
};

export const receiveOrders = ({
  orders,
  totalOrders,
  visibleOrders,
  showLoadMoreOrdersButton,
}) => {
  return {
    type: RECEIVE_ORDERS,
    orders,
    totalOrders,
    visibleOrders,
    showLoadMoreOrdersButton,
  };
};

export const fetchOrders = ({ offset }) => {
  return async (dispatch) => {
    dispatch(requestOrders());

    const identity = await sdkProvider("identity");
    const authHeader = await identity.getAuthHeader();
    const customerId = await identity.customer.customerId();
    const { orderSummaries: orders, itemCount: totalOrders } = await getOrders({
      authHeader,
      customerId,
      offset,
    });
    const visibleOrders = offset + orders.length;
    const showLoadMoreOrdersButton = visibleOrders < totalOrders;
    dispatch(
      receiveOrders({
        orders,
        totalOrders,
        visibleOrders,
        showLoadMoreOrdersButton,
      })
    );
  };
};

export const shouldFetchOrders = (state) => {
  const { loadingOrders, orders } = state.contactForm;

  if (orders.length === 0 && loadingOrders !== false) {
    return true;
  } else {
    return false;
  }
};

export const fetchOrdersIfNeeded = ({ offset }) => {
  return (dispatch, getState) => {
    if (shouldFetchOrders(getState())) {
      return dispatch(fetchOrders({ offset }));
    } else {
      Promise.resolve();
    }
  };
};

export const selectOrder = ({ order, step: nextStep }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { isAuthenticated, subtopic } = state.contactForm;
    const loadingTrackAndTrace =
      subtopic === "where_is_my_order" && isAuthenticated;
    const step = loadingTrackAndTrace ? "3" : nextStep;

    dispatch(setOrder({ order, step, loadingTrackAndTrace }));

    if (loadingTrackAndTrace) {
      const { orderReference } = order;
      const identity = await sdkProvider("identity");
      const authHeader = await identity.getAuthHeader();

      const { parcelStatusId, statusAchievedDateTime } =
        await getLatestTrackAndTraceEvent({ authHeader, orderReference });
      const hasTrackAndTrace =
        typeof parcelStatusId !== "undefined" &&
        typeof statusAchievedDateTime !== "undefined";

      dispatch(
        setTrackAndTrace({
          parcelStatusId,
          statusAchievedDateTime,
          hasTrackAndTrace,
        })
      );
    }
  };
};

export const skipOrder = () => {
  return { type: SKIP_ORDER };
};

export const resetOrder = () => {
  return { type: RESET_ORDER };
};

export const setOrder = ({ order, step, loadingTrackAndTrace }) => {
  return { type: SET_ORDER, order, step, loadingTrackAndTrace };
};

export const setTrackAndTrace = ({
  parcelStatusId,
  statusAchievedDateTime,
  hasTrackAndTrace,
}) => {
  return {
    type: SET_TRACK_AND_TRACE,
    parcelStatusId,
    statusAchievedDateTime,
    hasTrackAndTrace,
  };
};

export const requestMoreOrders = () => {
  return { type: REQUEST_MORE_ORDERS };
};

export const receiveMoreOrders = ({
  orders,
  totalOrders,
  visibleOrders,
  showLoadMoreOrdersButton,
}) => {
  return {
    type: RECEIVE_MORE_ORDERS,
    orders,
    totalOrders,
    visibleOrders,
    showLoadMoreOrdersButton,
  };
};

export const fetchMoreOrders = ({ offset }) => {
  return async (dispatch) => {
    dispatch(requestMoreOrders());

    const identity = await sdkProvider("identity");
    const authHeader = await identity.getAuthHeader();
    const customerId = await identity.customer.customerId();
    const { orderSummaries: orders, itemCount: totalOrders } = await getOrders({
      authHeader,
      customerId,
      offset,
    });
    const visibleOrders = offset + orders.length;
    const showLoadMoreOrdersButton = visibleOrders < totalOrders;
    dispatch(
      receiveMoreOrders({
        orders,
        totalOrders,
        visibleOrders,
        showLoadMoreOrdersButton,
      })
    );
  };
};
